var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { dataimpService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from '~/core/decorator';
import { Service } from '~/core/service';
var ExportTemplateService = /** @class */ (function (_super) {
    __extends(ExportTemplateService, _super);
    function ExportTemplateService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 多条件查询导出模板
     */
    ExportTemplateService.prototype.findAllExportTemplate = function (data, page, sort, loading) {
        return this.netService.send({
            server: dataimpService.exportTemplateController.findAllExportTemplate,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     * 获取导出字段
     */
    ExportTemplateService.prototype.getExportProperty = function (type, loading) {
        return this.netService.send({
            server: dataimpService.exportTemplateController.getExportProperty,
            data: {
                type: type
            },
            loading: loading
        });
    };
    /**
     * 新增导出模板
     */
    ExportTemplateService.prototype.addExportTemplate = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportTemplateController.addExportTemplate,
            data: {
                dataStartCol: data.dataStartCol,
                dataStartRow: data.dataStartRow,
                fileId: data.fileId,
                items: data.items,
                name: data.name,
                principalId: data.principalId,
                principalName: data.principalName,
                publicField: data.publicField,
                sheetNumber: data.sheetNumber,
                titleStartCol: data.titleStartCol,
                titleStartRow: data.titleStartRow,
                type: data.type
            },
            loading: loading
        });
    };
    /**
     * 删除导出模板
     */
    ExportTemplateService.prototype.deleteExportTemplate = function (templateId, loading) {
        return this.netService.send({
            server: dataimpService.exportTemplateController.deleteExportTemplate,
            data: {
                templateId: templateId
            },
            loading: loading
        });
    };
    /**
     * 获取导出模板信息
     */
    ExportTemplateService.prototype.getExportTemplateById = function (templateId, loading) {
        return this.netService.send({
            server: dataimpService.exportTemplateController.getExportTemplateById,
            data: {
                templateId: templateId
            },
            loading: loading
        });
    };
    /**
     * 修改导出模板
     */
    ExportTemplateService.prototype.modifyExportTemplate = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportTemplateController.modifyExportTemplate,
            data: data,
            loading: loading
        });
    };
    /**
     * 查询委托方下导出模板
     */
    ExportTemplateService.prototype.getExportTemplateByPrincipal = function (principalId, type, loading) {
        return this.netService.send({
            server: dataimpService.exportTemplateController.getExportTemplateByPrincipal,
            data: {
                principalId: principalId,
                type: type
            },
            loading: loading
        });
    };
    /**
     *
     */
    ExportTemplateService.prototype.checkExportTemplate = function (data, loading) {
        return this.netService.send({
            server: dataimpService.exportTemplateController.checkExportTemplate,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], ExportTemplateService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], ExportTemplateService.prototype, "findAllExportTemplate", null);
    __decorate([
        Debounce()
    ], ExportTemplateService.prototype, "getExportProperty", null);
    __decorate([
        Debounce()
    ], ExportTemplateService.prototype, "addExportTemplate", null);
    __decorate([
        Debounce()
    ], ExportTemplateService.prototype, "deleteExportTemplate", null);
    __decorate([
        Debounce()
    ], ExportTemplateService.prototype, "getExportTemplateById", null);
    __decorate([
        Debounce()
    ], ExportTemplateService.prototype, "modifyExportTemplate", null);
    __decorate([
        Debounce()
    ], ExportTemplateService.prototype, "getExportTemplateByPrincipal", null);
    __decorate([
        Debounce()
    ], ExportTemplateService.prototype, "checkExportTemplate", null);
    return ExportTemplateService;
}(Service));
export { ExportTemplateService };
